import React from "react";
import AgentDashboard from "../AgentDashboard/AgentDashboard";

const MainDashboard = () => {
  const role = JSON.parse(localStorage.getItem("role"));

  return (
    <>
      <AgentDashboard />
   
    </>
  );
};

export default MainDashboard;
