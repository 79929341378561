import React from "react";

const UnderscoreRemoveCapitalize = ({ text }) => {
  return (
    <>
      {
        <div style={{ textTransform: "capitalize" }}>
          {text && text?.replace(/_/g, " ")}
        </div>
      }
    </>
  );
};

export default UnderscoreRemoveCapitalize;
