import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

export default function ProtectedRoutes() {

    // const authenticated = useSelector(
    //     (state) => state.isAuthenticated.authenticated
    //   );

    const authenticated = JSON.parse(localStorage.getItem("authenticated"));

    // const authenticated  = JSON.parse(localStorage.getItem("selectedProperty"));
    // const authenticated = useSelector(
    //     (state) => state.isAuthenticated.authenticated
    //   );
    // console.log(selectedProperty);
      // console.log(authenticated);
    let auth = { token: authenticated };
    return auth.token ? <Outlet /> : <Navigate to="/" />;
}