// import React, { useState, useRef, useEffect } from "react";
// import "./SearchComponent.css";

// const SearchComponent = ({
//   searchArray: frontArray,
//   keyName,
//   getSelectedItem,
//   index,
//   selectedValue,
//   disabledOptions,
//   returnKeyName,
//   titleName,
//   searchFilterBy,
//   required,
//   error,
//   uniqueTrue,
//   uniqueKey,
// }) => {
//   const [searchTerm, setSearchTerm] = useState("");
//   const [searchResults, setSearchResults] = useState([]);
//   const [searchArray, setSearchArray] = useState(frontArray);
//   const [showNoData, setShowNoData] = useState(false);

//   useEffect(() => {
//     setSearchArray(frontArray);
//   }, [frontArray]);

//   useEffect(() => {
//     if (searchFilterBy) {
//       const data = searchArray.find(
//         (item) => item[searchFilterBy] === selectedValue
//       );

//       if (!data) return;

//       setSearchTerm(data?.[keyName]);
//     } else {
//       setSearchTerm(selectedValue);
//     }
//   }, [selectedValue]);

//   useEffect(() => {

//     if (uniqueTrue) {
//       if (uniqueKey) {
//         const uniqueByKey = (array, keyName) => {
//           return array.reduce((acc, current) => {
//             if (!acc.find((item) => item[uniqueKey] === current[uniqueKey])) {
//               acc.push(current);
//             }
//             return acc;
//           }, []);
//         };

//         const uniqueArray = uniqueByKey(frontArray, uniqueKey);
//         setSearchArray(uniqueArray);
//       } else {
//         const uniqueByKey = (array, keyName) => {
//           return array.reduce((acc, current) => {
//             if (!acc.find((item) => item[keyName] === current[keyName])) {
//               acc.push(current);
//             }
//             return acc;
//           }, []);
//         };

//         const uniqueArray = uniqueByKey(frontArray, keyName);
//         setSearchArray(uniqueArray);
//       }
//     }
//   }, [frontArray, uniqueTrue, uniqueKey, keyName]);

//   const inputRef = useRef(null);
//   const customSearchInputContainerParentRef = useRef(null);
//   const searchSuggestionRef = useRef(null);

//   const handleInputChange = (e) => {
//     const value = e.target.value;
//     setSearchTerm(value);
//     if (value === "") {
//       setSearchResults(searchArray);
//       setShowNoData(false);
//     } else {
//       const results = searchArray.filter((item) =>
//         item[keyName].toLowerCase().trim().includes(value.toLowerCase().trim())
//       );
//       setSearchResults(results);
//       setShowNoData(results.length === 0);
//     }
//   };
//   const handleDropdownClick = (e, item) => {
//     e.stopPropagation();
//     setSearchTerm(item[keyName]);

//     const sendToFrontEnd = returnKeyName
//       ? item[returnKeyName]
//         ? item[returnKeyName]
//         : item
//       : item;

//     getSelectedItem(sendToFrontEnd, index);
//     setSearchResults([]);
//   };

//   const handleInputFocus = () => {
//     const parentContainer = customSearchInputContainerParentRef.current;
//     parentContainer.classList.add("custom-search-input-container-focused");
//     setSearchResults(searchArray);
//   };

//   const handleInputBlur = () => {
//    ;
//     if (searchTerm) {
//       const data = searchArray.find((item) => item[keyName] === searchTerm);
//       console.log(data);

//       if (!data) {
//         setSearchTerm("");
//       }
//     }

//   };

//   const handleClickOutside = (e) => {
//     if (
//       customSearchInputContainerParentRef.current &&
//       !customSearchInputContainerParentRef.current.contains(e.target)
//     ) {

//       setTimeout(() => {
//         setShowNoData(false);
//         setSearchResults([]);
//       }, 100);
//       const parentContainer = customSearchInputContainerParentRef.current;
//       parentContainer.classList.remove("custom-search-input-container-focused");
//       inputRef.current.blur();
//     }
//   };
//   useEffect(() => {
//     document.addEventListener("click", handleClickOutside);
//     return () => {
//       document.removeEventListener("click", handleClickOutside);
//     };
//   }, []);

//   return (
//     <div style={{ position: "relative" }}>
//       <div className="customSearchLabelName">
//         {titleName}{" "}
//         {required === true ? <span style={{ color: "red" }}> *</span> : ""}
//       </div>
//       <div
//         className="customSearchInputContainerParent"
//         ref={customSearchInputContainerParentRef}
//       >
//         <div
//           className="customSearchInputContainer"
//           style={{
//             background: error ? "#FFEAEA" : "",
//             borderColor: error ? "red" : "",
//           }}
//         >
//           <input
//             style={{ background: error ? "#FFEAEA" : "" }}
//             type="text"
//             value={searchTerm}
//             onChange={handleInputChange}
//             onBlur={handleInputBlur}
//             onFocus={handleInputFocus}
//             placeholder="Search..."
//             ref={inputRef}
//             className="customSearchInput"
//             onKeyDown={(e) => {
//               if (e.key === "Backspace") {
//                 getSelectedItem("", "");
//                 setSearchTerm("");
//                 setSearchResults([]);
//               }
//             }}
//           />
//         </div>
//       </div>
//       {searchResults.length > 0 && (
//         <ul
//           className="overflowContainer customSearchSuggestion"
//           ref={searchSuggestionRef}
//         >
//           {searchResults.map((item, index) => (
//             <li key={index} className="customSearchSuggestionList">
//               <button
//                 onClick={(e) => handleDropdownClick(e, item)}
//                 className="searchSuggestionButton"
//                 disabled={
//                   disabledOptions && disabledOptions?.includes(item[keyName])
//                 }
//                 onKeyDown={(e) => {
//                   if (e.key === "Enter") {
//                     handleDropdownClick(e, item);
//                   }
//                 }}
//               >
//                 {item[keyName]}
//               </button>
//             </li>
//           ))}
//         </ul>
//       )}
//       {showNoData && (
//         <div
//           className="customSearchSuggestion"
//           style={{ textAlign: "center", padding: "5px" }}
//         >
//           No Data Found
//         </div>
//       )}
//     </div>
//   );
// };

// export default SearchComponent;

import React, { useState, useRef, useEffect } from "react";
import "./SearchComponent.css";

const SearchComponent = ({
  searchArray: frontArray,
  keyName,
  getSelectedItem,
  index,
  selectedValue,
  disabledOptions,
  returnKeyName,
  titleName,
  searchFilterBy,
  required,
  error,
  uniqueTrue,
  uniqueKey,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchArray, setSearchArray] = useState(frontArray);
  const [showNoData, setShowNoData] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(-1); // State to keep track of the focused item index

  useEffect(() => {
    setSearchArray(frontArray);
  }, [frontArray]);

  useEffect(() => {
    if (searchFilterBy) {
      const data = searchArray.find(
        (item) => item[searchFilterBy] === selectedValue
      );

      if (!data) return;

      setSearchTerm(data?.[keyName]);
    } else {
      setSearchTerm(selectedValue);
    }
  }, [selectedValue]);
  useEffect(() => {
    if (uniqueTrue) {
      if (uniqueKey) {
        const uniqueByKey = (array, keyName) => {
          return array?.reduce((acc, current) => {
            if (!acc?.find((item) => item[uniqueKey] === current[uniqueKey])) {
              acc?.push(current);
            }
            return acc;
          }, []);
        };

        const uniqueArray = uniqueByKey(frontArray, uniqueKey);
        setSearchArray(uniqueArray);
      } else {
        const uniqueByKey = (array, keyName) => {
          return array.reduce((acc, current) => {
            if (!acc.find((item) => item[keyName] === current[keyName])) {
              acc.push(current);
            }
            return acc;
          }, []);
        };

        const uniqueArray = uniqueByKey(frontArray, keyName);
        setSearchArray(uniqueArray);
      }
    }
  }, [frontArray, uniqueTrue, uniqueKey, keyName]);

  const inputRef = useRef(null);
  const customSearchInputContainerParentRef = useRef(null);
  const searchSuggestionRef = useRef(null);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (value === "") {
      setSearchResults(searchArray);
      setShowNoData(false);
    } else {
      const results = searchArray.filter((item) =>
        item[keyName]?.toLowerCase()?.trim()?.includes(value?.toLowerCase()?.trim())
      );
      setSearchResults(results);
      setShowNoData(results?.length === 0);
    }
  };
  const handleDropdownClick = (e, item) => {
    e.stopPropagation();
    setSearchTerm(item[keyName]);

    const sendToFrontEnd = returnKeyName
      ? item[returnKeyName]
        ? item[returnKeyName]
        : item
      : item;

    getSelectedItem(sendToFrontEnd, index);
    setSearchResults([]);
  };

  const handleInputFocus = () => {
    const parentContainer = customSearchInputContainerParentRef.current;
    parentContainer.classList.add("custom-search-input-container-focused");
    setSearchResults(searchArray);
  };

  const handleInputBlur = () => {
    if (searchTerm) {
      const data = searchArray.find((item) => item[keyName] === searchTerm);

      if (!data) {
        setSearchTerm("");
      }
    }
  };

  const handleClickOutside = (e) => {
    if (
      customSearchInputContainerParentRef.current &&
      !customSearchInputContainerParentRef.current.contains(e.target)
    ) {
      setTimeout(() => {
        setShowNoData(false);
        setSearchResults([]);
      }, 100);
      const parentContainer = customSearchInputContainerParentRef.current;
      parentContainer.classList.remove("custom-search-input-container-focused");
      inputRef.current.blur();
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      
      setFocusedIndex((prevIndex) =>
        prevIndex < searchResults?.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setFocusedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    } else if (e.key === "Enter" && focusedIndex !== -1) {
      handleDropdownClick(e, searchResults[focusedIndex]);
    }else if (e.key === "Backspace") {
      setSearchTerm("");
      setSearchResults(searchArray);
      setShowNoData(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
    useEffect(() => {
      // Scroll to the focused item in the parent container
      if (searchSuggestionRef.current && focusedIndex !== -1) {
        const listItem = searchSuggestionRef.current.childNodes[focusedIndex];
        if (listItem) {
          listItem.scrollIntoView({ block: "nearest" });
        }
      }
    }, [focusedIndex]);

 const getHighlightedText = (text, highlight) => {
    const parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return parts.map((part, index) =>
      part?.toLowerCase() === highlight?.toLowerCase() ? (
        <span key={index} style={{color: "red"}}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  return (
    <div style={{ position: "relative" }}>
      <div className="customSearchLabelName">
        {titleName}{" "}
        {required === true ? <span style={{ color: "red" }}> *</span> : ""}
      </div>
      <div
        className="customSearchInputContainerParent"
        ref={customSearchInputContainerParentRef}
      >
        <div
          className="customSearchInputContainer"
          style={{
            background: error ? "#FFEAEA" : "",
            borderColor: error ? "red" : "",
          }}
        >
          <input
            style={{ background: error ? "#FFEAEA" : "" }}
            type="text"
            value={searchTerm}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            onFocus={handleInputFocus}
            placeholder="Search..."
            ref={inputRef}
            className="customSearchInput"
            onKeyDown={handleKeyDown}
          />
        </div>
      </div>
      {searchResults?.length > 0 && (
        <ul
          className="overflowContainer customSearchSuggestion"
          ref={searchSuggestionRef}
        >
          {searchResults.map((item, index) => (
            <li
              key={index}
              className={`customSearchSuggestionList ${
                focusedIndex === index ? "customSearchSuggestionListHover" : ""
              }`}

              // style={index === focusedIndex ? { backgroundColor: "#f0f0f0" } : {}}
            >
              <button
                onClick={(e) => handleDropdownClick(e, item)}
                className="searchSuggestionButton"
                disabled={
                  disabledOptions && disabledOptions?.includes(item[keyName])
                }
              >
                
                {getHighlightedText(item[keyName], searchTerm)}
              </button>
            </li>
          ))}
        </ul>
      )}
      {showNoData && (
        <div
          className="customSearchSuggestion"
          style={{ textAlign: "center", padding: "5px" }}
        >
          No Data Found
        </div>
      )}
    </div>
  );
};

export default SearchComponent;
