import React from "react";
import "./TruncatedText.css";

const TruncatedText = ({width,longText,fontSize, justifyContent}) => {
  return (
      <div className="truncatedTextContainer" style={{fontSize:fontSize}}>
        <div className="truncatedTextContainer_text" style={{ width: width }}>
       {longText}
        </div>
        <div className="truncatedTextContainer_text_tooltip">
          {longText}
        </div>
      </div>
  );
};

export default TruncatedText;
