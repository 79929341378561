
import styles from "./selectPropertyPopup.module.scss";
import React, { useState, useEffect } from "react";
import { showErrorToast } from "../../assets/toastUtils";
import LoaderSmall from "../UI/Loader/LoaderSmall";
import { useNavigate } from "react-router-dom";
import { SvgCancel } from "../../assets/SvgIcons";


const SelectPropertyPopup = ({switchProperty, setSwitchProperty}) => {
 const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [properties, setProperties] = useState([]);
  const [agent, setAgent] = useState({});
  const [agentDetails, setAgentDetails] = useState(null);
  const userToken = JSON.parse(localStorage.getItem("userToken"));

  useEffect(() => {
    const storedAgentDetails = JSON.parse(localStorage.getItem("agentDetails"));
    setAgentDetails(storedAgentDetails);
  }, []);

  const fetchDashboardInfo = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agent/details/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setProperties(data.properties);
      setAgent(data.agentInfo);
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (agentDetails && agentDetails.id) {
      fetchDashboardInfo(agentDetails.id);
    }
  }, [agentDetails]);

  
  return (
    <>
      <div className={styles.container}>
      <div className={styles.popupContainer}>
        

      
      {loader ? (
            <LoaderSmall/>
          ) : (
            <div>
              <div className="common_title text_center  flex align_items_center justify_content_between">
                <h3>Select Property</h3>
                <span onClick={() => setSwitchProperty(false)}>
                <SvgCancel  color={ "#333"}/>
                </span>
              </div>
              <div style={{ marginTop: "10px", textAlign: 'center' }}>
                <select
                  style={{ width: "100%" ,padding:"10px",borderRadius:"4px" }}
                  className={styles.selectTag}
                  onChange={(e) => {
                    const selectedPropertyId = e.target.value;
              
                
                    // Find the selected property object based on the ID
                    const selectedProperty = properties.find(property => +property.id === +selectedPropertyId);
                
                    // Save the entire selected property object in local storage
                    if (selectedProperty) {
                       setSwitchProperty(false)
                        localStorage.setItem("selectedProperty", JSON.stringify(selectedProperty)); // Save the entire object as a string
                        navigate(`/dashboard/${selectedPropertyId}`); // Navigate to the dashboard with the selected property ID
                    }
                }}
                >
                  <option value="">Select a property</option>
                  {properties.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.property_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}



      </div>
    </div>
    </>
  );
};

export default SelectPropertyPopup;



