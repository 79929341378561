import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    // token: localStorage.getItem('token'),
    authenticated: null,
    // loading: true,
    // user: null,
};
export const isAuthenticatedSlice = createSlice({
    name: 'isAuthenticated',
    initialState,
    reducers: {
        checkIsAuthenticatedTrue: (state, action) => {
            // state.value = action.payload;
            localStorage.setItem('authenticated', JSON.stringify(action.payload));
                state.authenticated = true;
        },
        checkIsAuthenticatedFalse: (state, action) => {
            // state.value = action.payload;
            localStorage.setItem('authenticated', JSON.stringify(action.payload));
            state.authenticated = false;

        },
        clearIsAuthenticated: (state, action) => {
            // state.value = action.payload;
            localStorage.removeItem('authenticated');
        },
        loginFailed: (state, action) => {
            state.authenticated = false;
          },
    },
})

// Action creators are generated for each case reducer function
export const { checkIsAuthenticatedTrue, checkIsAuthenticatedFalse, clearIsAuthenticated } = isAuthenticatedSlice.actions;

export default isAuthenticatedSlice.reducer;