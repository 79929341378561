// src/pages/ThankYouPage.js
import React, { useEffect, useState } from "react";
import styles from "../Components/styles/pages/paymentStatus.module.scss";
import { useLocation, useNavigate } from "react-router-dom";

const ThankYouPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [countdown, setCountdown] = useState(5);
  const [apiCalled, setApiCalled] = useState(false);

  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const getQueryParams = () => {
    const queryParams = new URLSearchParams(location.search);
    const params = {};
    queryParams.forEach((value, key) => {
      params[key] = value;
    });
    return params;
  };

  const queryParams = getQueryParams();

  const sentPaymentDetails = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agent/booking-engine/update-booking`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify(queryParams),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        console.error(`HTTP error! status: ${response.status}`, errorData);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (!apiCalled) {
      sentPaymentDetails();
      setApiCalled(true);
    }
  }, [apiCalled, queryParams]);

  useEffect(() => {
    if (countdown <= 0) {
      navigate("/bookings");
      return;
    }

    const timer = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [countdown, navigate]);

  return (
    <div className="main_container">
      <div className={styles.dialogOverlay}>
        <div className={styles.dialogBox}>
          <div className={`${styles.messageBox} ${styles._success}`}>
            <svg
              version="1.1"
              viewBox="0 0 2048 2048"
              width="60"
              height="60"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                transform="translate(973)"
                d="m0 0h104l61 6 46 6 45 8 48 11 47 13 48 16 46 18 36 16 25 12 26 13 23 13 26 15 19 12 17 11 19 13 18 13 19 14 16 13 9 7 13 11 14 12 10 9 8 7 15 14 10 10 8 7 5 5v2h2l7 8 10 10 7 8 12 13 9 11 10 11 11 14 12 15 10 13 13 18 12 17 12 18 14 22 12 20 12 21 12 22 9 17 11 23 15 33 16 40 9 25 12 36 13 46 12 52 8 45 7 56 5 55v105l-6 59-6 45-5 30-9 42-9 36-14 47-13 38-20 50-9 20-14 29-18 36-14 24-10 17-13 21-13 20-30 42-13 17-8 10-9 11-24 28-10 11-7 8-14 14-7 8-15 15-8 7-13 13-11 9-12 11-11 9-15 13-17 13-18 14-19 13-15 11-17 11-19 12-20 12-24 14-33 17-17 9-33 15-36 15-29 11-36 12-33 10-48 12-38 8-49 8-60 7-36 3h-92l-35-3-56-6-40-6-37-7-39-9-43-12-28-9-35-12-40-16-29-13-28-13-23-12-19-10-17-10-22-13-25-16-18-12-18-13-14-10-13-10-14-11-16-13-14-12-15-13-12-11-7-7-8-7-35-35-7-8-8-8-7-8-11-13-9-10-11-14-12-15-10-13-13-18-12-17-14-21-14-22-9-15-16-28-10-18-8-16-16-33-14-32-16-41-16-47-12-42-10-40-8-40-7-45-5-42-4-46v-105l6-61 5-39 7-41 9-41 9-36 11-37 16-47 18-45 19-42 17-34 12-22 14-24 9-15 15-23 7-11 24-34 9-12 11-14 14-18 9-10 9-11 10-11 7-8 12-13 7-7 7-8 19-19 8-7 11-11 8-7 14-12 10-9 11-9 14-11 15-12 16-12 17-12 20-14 16-10 17-11 17-10 21-12 22-12 19-10 28-13 31-14 38-15 40-14 29-9 41-11 39-9 43-8 50-7 52-5z"
                fill="#34B97F"
              />
              <path
                transform="translate(1697,672)"
                d="m0 0 7 5 3 4v2h3v2l4 2 17 16 7 7 2 1v2l4 2 8 8 8 7 15 14 5 4v2l4 2v2l4 2 7 6 5 5 4 3v2l4 2 4 3v2l4 2 6 5 5 5 1 3 4 2 8 7v2l4 2 8 7 5 5 3 2v2l4 2 10 10 2 1v2l4 2 10 10 10 8v2l4 2 16 15 10 9 7 7 8 7 15 14 8 7 15 14 12 11 8 7 15 14 2 1v2l4 2 13 12 11 9v88l-6 59-6 45-5 30-9 42-9 36-14 47-13 38-20 50-9 20-14 29-18 36-14 24-10 17-13 21-13 20-30 42-13 17-8 10-9 11-24 28-10 11-7 8-14 14-7 8-15 15-8 7-13 13-11 9-12 11-11 9-15 13-17 13-18 14-19 13-15 11-17 11-19 12-20 12-24 14-33 17-17 9-33 15-36 15-29 11-36 12-33 10-12 3h-3l-3-6v-2l-4-2-36-36v-2l-3-1-4-5-7-6-6-7-7-6v-2l-4-2v-2l-3-1-5-6-5-5-10-9-339-339-5-4v-2l-4-2-4-4v-2h-2l-7-8-7-7-8-7v-1l7 1 5 2 16 4 17 3h24l14-2 20-6 17-8 16-12 15-14 83-83 7-8 14-15 11-12 18-18h2v-2h2v-2l8-7 224-224h2l2-4h2l2-4h2l2-4h2l2-4h2l2-4h2l2-4h2l2-4h2l2-4h2l2-4h2l2-4h2l2-4h2l2-4 8-7 236-236 6-5 6-7 6-5 6-7 6-5 5-6 7-6 9-11 8-11 8-16 4-11 2-6h2l1-8 2-15v-16l-2-15-5-19z"
                fill="#059F70"
              />
              <path
                transform="translate(1483,506)"
                d="m0 0h20l19 3 18 6 19 10 11 9 10 9 8 7 80 80 9 11 9 12 8 15 6 18 3 16 1 9v11l-3 20-5 18-8 17-10 14-11 13h-2l-2 4h-2l-2 4h-2l-1 3-6 5-6 7-6 5-6 7-8 7-240 240-7 6-5 6-7 6-5 6-7 6-5 6-7 6-5 6-8 7-224 224h-2v2h-2v2l-8 7-19 19-7 8-15 16-9 10-86 86-11 9-13 9-15 7-20 6-14 2h-24l-17-3-18-6-19-10-11-9-12-11-86-86-5-6-6-5-7-8-110-110-7-8-100-100v-2h-2v-2h-2l-7-8-74-74-13-17-7-12-6-15-5-21-1-7v-17l3-21 6-20 9-17 9-12 18-20 7-7h2l2-4 8-7 67-67 16-12 15-8 18-6 9-2 9-1h28l13 2 17 5 16 8 11 7 13 11 95 95v2l4 2 5 6 6 5 6 7 6 5 7 8 101 101 3 1 8-8 6-7 8-7 153-153h2l1-3 8-7 241-241h2l2-4 8-7 98-98 13-10 13-8 14-6 18-5z"
                fill="#FEFEFE"
              />
              <path
                transform="translate(1184,1284)"
                d="m0 0v3l-123 123v-3l10-10 7-8 20-20h2v-2h2v-2l8-7z"
                fill="#21AF79"
              />
              <path
                transform="translate(1664,806)"
                d="m0 0v3l-19 19h-2v2h-2l1-3 6-5 6-7 6-5z"
                fill="#35BA80"
              />
            </svg>

            <h2>Your payment was successful</h2>

            <p>
              Redirecting you to the booking page in{" "}
              <strong style={{ color: "#39682d" }}>{countdown}</strong>{" "}
              seconds...
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
