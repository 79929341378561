import React from "react";
import "./GoBackButtonCustom.css";

const GoBackButtonCustom = ({ buttonNextText, onClick, style }) => {
  return (
    <div className="GoBackButtonCustom" style={style}>
      <div onClick={onClick} style={{ marginRight: "10px", cursor: "pointer" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
        >
          <rect
            x="29.75"
            y="29.75"
            width="29.5"
            height="29.5"
            rx="5.75"
            transform="rotate(180 29.75 29.75)"
            stroke="#666666"
            strokeWidth="0.5"
          />
          <line x1="21" y1="15.043" x2="9" y2="15.043" stroke="#666666" />
          <path
            d="M13.6287 19.2578L9.00009 14.6292L13.6287 10.0007"
            stroke="#666666"
            strokeWidth="1.2"
          />
        </svg>
      </div>
      {buttonNextText}
    </div>
  );
};

export default GoBackButtonCustom;
