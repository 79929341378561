import React, { useEffect, useRef } from "react";
import "./CreateBookingEditableInput.css";
import { useState } from "react";
const   CreateBookingEditableInput = ({
  label,
  placeholder,
  value,
  onChange,
  scale,
  name,
  disabled,
  error,
  onFocus,
}) => {
  const inputRef = useRef(null);
  const [focus, setFocus] = useState(false);

  const handleIconClick = () => {
    inputRef.current.disabled = !inputRef.current.disabled;
    if (!inputRef.current.disabled) {
      inputRef.current.focus();
    }
    setFocus(!inputRef.current.disabled);
  };

  const  handleIconClickDisabled = ()=>{
       
  }

  const handleBlurAction = () => {
    inputRef.current.disabled = true;
    setFocus(false);
  };
  return (
    <div>
      {scale === "large" ? (
        <div className="create_booking_editable_input_container">
          <label className="Create_booking_label_small">{label}</label>
          <div
            className="common_min_input d_flex gap_7"
            style={{
              border: focus ? "1px solid #3968ed" : error ? "1px solid #E03838" : "",
              // border: error ? "1px solid #E03838" : "",
              backgroundColor: focus ? "#fff" : error ? "#FFEAEA" : "",
              // backgroundColor: error ? "#FFEAEA" : "",
             
              
            }}
          >
            <input
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              disabled={disabled}
              ref={inputRef}
              name={name}
              onFocus={onFocus}
              onBlur={handleBlurAction}
            ></input>
            <svg
              width="11"
              height="11"
              viewBox="0 0 11 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={disabled ? handleIconClickDisabled : handleIconClick}
              style={disabled ? { cursor: "not-allowed" }  :  { cursor: "pointer" }}
            >
              <g clipPath="url(#clip0_5205_24822)">
                <path
                  d="M8.40292 1.02256L10.2788 2.97704L10.5917 2.65107C11.1096 2.11144 11.1096 1.23666 10.5917 0.697025C10.0736 0.157225 9.23346 0.157225 8.71536 0.697025L8.40292 1.02256Z"
                  fill="#888888"
                />
                <path
                  d="M9.41751 3.87236L7.54162 1.91788L1.58902 8.11881L0.980103 10.7077L3.46491 10.0733L9.41751 3.87236Z"
                  fill="#888888"
                />
              </g>
              <defs>
                <clipPath id="clip0_5205_24822">
                  <rect
                    width="10"
                    height="10.4167"
                    fill="white"
                    transform="translate(0.980072 0.291687)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      ) : (
        <div className="d_flex gap_7">
          <label className="Create_booking_label_small">{label}</label>
          <div
            className="rate_input_small d_flex"
            style={{ border: focus ? "1px solid #3968ed" : "" }}
          >
            <input
              placeholder={placeholder}
              value={value}
              disabled={disabled}
              onChange={onChange}
              ref={inputRef}
              onBlur={handleBlurAction}
              name={name}
              onFocus={onFocus}
            ></input>
            <svg
              width="11"
              height="11"
              viewBox="0 0 11 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={handleIconClick}
              style={{ cursor: "pointer" }}
            >
              <g clipPath="url(#clip0_5205_24822)">
                <path
                  d="M8.40292 1.02256L10.2788 2.97704L10.5917 2.65107C11.1096 2.11144 11.1096 1.23666 10.5917 0.697025C10.0736 0.157225 9.23346 0.157225 8.71536 0.697025L8.40292 1.02256Z"
                  fill="#888888"
                />
                <path
                  d="M9.41751 3.87236L7.54162 1.91788L1.58902 8.11881L0.980103 10.7077L3.46491 10.0733L9.41751 3.87236Z"
                  fill="#888888"
                />
              </g>
              <defs>
                <clipPath id="clip0_5205_24822">
                  <rect
                    width="10"
                    height="10.4167"
                    fill="white"
                    transform="translate(0.980072 0.291687)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      )}
    </div>
  );
};
export default CreateBookingEditableInput;
