import React from "react";

const VerticalTextAreaCustom = ({
  cols,
  rows,
  titleName,
  name,
  onChange,
  value,
  type,
  defaultValue,
  label,
  required,
  placeholder,
  disabled,
}) => {
  return (
    <div>
      <div className="Polaris-Labelled__LabelWrapper">
        <div className="Polaris-Label">
          <label className="Polaris-Label__Text">
            {titleName}{" "}
            {required === true ? <span style={{ color: "red" }}> *</span> : ""}
          </label>
        </div>
      </div>

      <div className="Polaris-connected">
        <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
          <div className="Polaris-TextField">
            <textarea
              disabled={disabled}
              placeholder={placeholder}
              required={required}
              defaultValue={defaultValue}
              autoComplete="off"
              className="Polaris-TextField__Input"
              name={name}
              onChange={onChange}
              value={value}
              type={type}
              cols={cols}
              rows={rows}
            ></textarea>
            <div className="Polaris-TextField__Backdrop"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerticalTextAreaCustom;
