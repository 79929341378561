import React, { useState, useEffect, useRef } from "react";

const User = ({ agentDetails ,switchProperty, setSwitchProperty}) => {
  const [show, setShow] = useState(false);
  const ref = useRef(null);

  const logoutHandle = (e) => {
    e.preventDefault();
    localStorage.clear();
    window.location.reload();
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShow(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  
  return (
   <>
    <div
      ref={ref}
      className="nav-user"
      onClick={() => setShow(!show)}
      style={{ position: "relative", width: "max-content", cursor: "pointer" }}
    >
      <img src="/user.jpg" alt="user image" />
      <div className="nav-user-info">
        Hi, {agentDetails?.agent_name}
        &nbsp; &nbsp;
        <svg
          width="12"
          height="7"
          viewBox="0 0 12 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={show ? { transform: "rotate(180deg)" } : {}}
        >
          <path d="M11 1L6 6L1 1" stroke="#333333" stroke-width="1.2"></path>
        </svg>
      </div>

      {show && (
        <>
          <div
            style={{
              position: "absolute",
              top: "110%",
              background: "#fff",
              padding: "5px",
              borderRadius: "4px",
              width: "100%",
              boxShadow: "0px 0px 10px 0px #ccc",
            }}
          >
            <button
              className="icon_button_only"
              onClick={()=>setSwitchProperty(!switchProperty)}
              style={{
                padding: "5px",
                width: "100%",
                // color: "red",
                fontFamily: "Inter",
                fontWeight: "500",
                fontSize: "12px",
                boxShadow: "0px 0px 10px 0px #ccc",
                borderRadius: "4px",
                color: "#666",
              }}
            >
              Switch Property
            </button>
            <button
              className="icon_button_only"
              onClick={logoutHandle}
              style={{
                paddingTop: "5px",
                width: "100%",
                color: "red",
                fontFamily: "Inter",
                fontWeight: "500",
                fontSize: "12px",
              }}
            >
              Log Out
            </button>

            
          </div>
        </>
      )}


      
    </div>


   </>
  );
};

export default User;
