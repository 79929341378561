import React, { useEffect, useState } from "react";
import styles from "../Components/styles/pages/paymentStatus.module.scss";
import { useLocation, useNavigate } from "react-router-dom";


const CancelPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [countdown, setCountdown] = useState(5);
  const [apiCalled, setApiCalled] = useState(false);

  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const getQueryParams = () => {
    const queryParams = new URLSearchParams(location.search);
    const params = {};
    queryParams.forEach((value, key) => {
      params[key] = value;
    });
    return params;
  };

  const queryParams = getQueryParams();

  const sentPaymentDetails = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agent/booking-engine/update-booking`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify(queryParams),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        console.error(`HTTP error! status: ${response.status}`, errorData);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (!apiCalled) {
      sentPaymentDetails();
      setApiCalled(true);
    }
  }, [apiCalled, queryParams]);

  useEffect(() => {
    if (countdown <= 0) {
      navigate("/dashboard");
      return;
    }

    const timer = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [countdown, navigate]);

  return (
    <div className="main_container">
      <div className={styles.dialogOverlay}>
        <div className={styles.dialogBox}>
          <div className={`${styles.messageBox} ${styles._failed}`}>
            <svg
              version="1.1"
              viewBox="0 0 2048 2048"
              width="60"
              height="60"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                transform="translate(999,64)"
                d="m0 0h54l38 2 36 3 40 5 41 7 37 8 42 11 36 11 35 12 35 14 25 11 24 11 26 13 22 12 28 16 22 14 33 22 18 13 16 12 14 11 16 13 14 12 10 9 11 9 14 14 8 7 23 23 7 8 9 9 7 8 24 28 12 15 13 17 10 13 21 30 15 23 13 21 15 26 13 24 17 34 16 36 12 30 13 36 11 35 12 44 9 41 7 40 5 38 4 44 1 17 1 35v22l-1 38-2 30-4 40-6 42-9 47-13 52-9 30-16 47-14 35-10 23-13 28-13 26-12 22-11 19-9 15-12 19-10 15-16 23-24 32-9 11-13 16-9 10-9 11-31 33-20 20h-2l-1 3-8 7-16 15-11 9-10 9-14 11-17 14-18 13-15 11-36 24-21 13-24 14-22 12-23 12-36 17-36 15-29 11-35 12-49 14-29 7-33 7-49 8-45 5-42 3-53 1-38-1-41-3-36-4-27-4-30-5-42-9-38-10-33-10-41-14-40-16-27-12-29-14-31-16-24-14-27-16-30-20-17-12-15-11-13-10-14-11-13-11-11-9-12-11-8-7-12-11-17-16-19-19-7-8-7-7-7-8-10-11-9-11-12-14-13-17-14-18-10-14-14-20-11-17-12-19-13-22-16-29-13-25-13-28-11-25-15-38-11-31-13-43-13-52-7-35-7-44-5-45-2-29-1-22v-68l2-35 3-33 5-38 7-41 8-37 9-36 12-39 10-30 10-26 11-27 12-27 14-29 13-25 13-23 12-20 12-19 16-24 12-17 18-24 11-14 13-16 9-10 9-11 14-15 9-10 39-39 8-7 7-7 11-9 11-10 14-11 13-11 19-14 16-12 19-13 15-10 22-14 22-13 27-15 23-12 22-11 34-15 30-12 30-11 37-12 36-10 38-9 42-8 33-5 35-4 41-3zm285 625-13 4-11 6-12 11-222 222-5-1-4-5-5-5-4-2-2-4-10-10-8-7-5-6h-2l-2-4h-2l-2-4-6-5-5-6-7-6-16-16-5-6-7-6-5-6-7-6-6-7-6-5-5-6-8-7-98-98-14-9-16-5h-22l-14 4-12 7-10 9-7 9-5 10-4 14v20l3 12 6 12 8 10 225 225v4l-7 6-7 8-217 217-8 14-3 9-2 17 2 13 5 14 7 10 9 10 10 7 10 5 12 3h18l12-3 14-7 11-9 225-225 4 2 228 228 11 7 9 4 11 3h19l14-4 11-6 11-9 10-13 5-11 3-12v-18l-3-12-7-14-11-12-7-8h-2l-2-4-16-16h-2l-2-4h-2l-1-3-7-6-5-5-4-5-5-5h-2l-2-4-6-5-5-5-4-5-8-7-4-5-7-6-5-6-8-7-4-5-7-6-5-6-7-6-93-93-1-3 1-3 4-2 223-223 7-10 6-12 3-13v-16l-3-13-7-14-8-10-11-9-15-7-9-2z"
                fill="#F84735"
              />
            </svg>
            <h2>Your payment was canceled</h2>
            <p>There was an issue with your payment. Please try again.</p>
            <p>
              Redirecting you to the dashboard page in{" "}
              <strong style={{ color: "#39682d" }}>{countdown}</strong>{" "}
              seconds...
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelPage;
