import React from "react";
import styles from "./balanceSummaryCard.module.scss";
import HandleNaN from "../../../CustomComponents/HandleNaN/HandleNaN";

const BalanceSummaryCard = ({
  heading,
  firstLimitHeading,
  secondLimitHeading,
  firstLimitValue,
  secondLimitValue,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.headingContainer}>
        <h3>{heading}</h3>
      </div>

      <div>
        <div className={styles.limitsContainer}>
          <div className={styles.limitContainerChild}>
            <h4>{firstLimitHeading}</h4>
            <h2>
              <HandleNaN
                currency={"₹"}
                value={firstLimitValue}
                decimal={false}
              />
            </h2>
          </div>
          <div className={styles.limitContainerChild}>
            <h4>{secondLimitHeading}</h4>
            <h2>
              <HandleNaN
                currency={"₹"}
                value={secondLimitValue}
                decimal={false}
              />
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BalanceSummaryCard;
