import React, { useState } from "react";
import { popupStyles } from "./styles";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import SelectDateRange from "../CustomComponents/SelectDateRange/SelectDateRange";
import VerticalInputSelectCustomCopy from "../CustomComponents/VerticalInputSelectCustomCopy";
import { BgOutlineThemeButton, BgThemeButton } from "../UI/Buttons";
import { SvgGoBack } from "../../assets/SvgIcons";

const CreateContract = () => {
  const releaseOptionsPercent = [
    { label: "select", value: "" },
    { label: "100%", value: "100" },
    { label: "75%", value: "75" },
    { label: "50%", value: "50" },
    { label: "25%", value: "25" },
    { label: "0%", value: "0" },
  ];
  const [holdRoomPopup, setHoldRoomPopup] = useState(false);
  const [checkedCategories, setCheckedCategories] = useState({});
  const handleHoldRoomsButtonClick = () => {
    setHoldRoomPopup(true);
  };
  const [selectedAgent, setSelectedAgent] = useState("contracted");
  const handleRadioAgentChange = (agentType) => {
    setSelectedAgent(agentType);
  };

  const handleDateChange = (form_date, to_date) => { };

  const roomCategories = [
    "Deluxe",
    "Super Deluxe",
    "Luxury",
    "Executive",
    "Family Suite",
  ];
  const handleCheckboxChange = (category) => {
    setCheckedCategories((prev) => {
      const newCheckedCategories = { ...prev, [category]: !prev[category] };

      // Call API if checkbox is checked
      if (newCheckedCategories[category]) {
       
        newCheckedCategories[`${category}Input`] = "API response value";
      } else {
        // Remove the input value if unchecked
        delete newCheckedCategories[`${category}Input`];
      }

      return newCheckedCategories;
    });
  };

  return (

    <>
      <div className="create-contract-container" style={popupStyles.container}>

        <div style={popupStyles.containerChild}>
          <span className="flex align_items_center flex_gap_10">
            <span >
            <SvgGoBack />
            </span>
          
            <h4 style={popupStyles.popupHeadings}>Create Contract</h4>
          </span>

          <div>
            <table style={popupStyles.tableBig}>
              <thead>
                <tr>
                  <th style={{ paddingRight: "5px" }}>Select Agent</th>
                  <th style={{ padding: "0 5px" }}>Select Date Range</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ paddingRight: "5px" }}>
                    <VerticalInputSelectCustomCopy />
                  </td>
                  <td style={{ padding: "0 5px" }}>
                    <SelectDateRange />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* ===========room Categories=========== */}
          <div>
            <div className="flex justify_content_between align_items_center">
              <h4 style={popupStyles.categoryHeading}>Rooms/Day</h4>
              <p>
                <i>Total Hold Rooms: 0</i>
              </p>
            </div>
            {roomCategories.map((item, index) => (
              <div
                key={index}
                style={{ height: "40px" }}
                className="flex justify_content_between align_items_center"
              >
                <div>
                  <label className="flex flex_gap_10 pointer">
                    <input
                      type="checkbox"
                      checked={!!checkedCategories[item]}
                      onChange={() => handleCheckboxChange(item)}
                    />{" "}
                    <span>{item}</span>
                  </label>
                </div>
                <div>
                  {checkedCategories[item] && (
                    <div className="flex flex_gap_15">
                      <div className="flex flex_gap_5 align_items_center">
                        <span>Avl. Rooms</span>
                        <VerticalInputFieldCustom
                          disabled={true}
                          style={popupStyles.smallInput}
                        />
                      </div>

                      <div className="flex flex_gap_5 align_items_center">
                        <span>Hold Rooms</span>
                        <VerticalInputFieldCustom
                          style={popupStyles.smallInput}
                          type="text"
                          value={checkedCategories[`${item}Input`] || ""}
                          readOnly
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <hr />

        <div style={popupStyles.containerChild}>
          <div className="flex justify_content_between align_items_center">
            <div>
              <h4 style={popupStyles.categoryHeading}> Release Restrictions :</h4>
              <div className="flex flex_gap_10">
                <div className="flex flex_gap_5 align_items_center">
                  <label>D-7</label>
                  <VerticalInputSelectCustomCopy
                    options={releaseOptionsPercent}
                    style={{ width: "50px", minHeight: "25px" }}
                  />
                </div>

                <div className="flex flex_gap_5 align_items_center">
                  <label>D-5</label>
                  <VerticalInputSelectCustomCopy
                    options={releaseOptionsPercent}
                    style={{ width: "50px", minHeight: "25px" }}
                  />
                </div>

                <div className="flex flex_gap_5 align_items_center">
                  <label>D-3</label>
                  <VerticalInputSelectCustomCopy
                    options={releaseOptionsPercent}
                    style={{ width: "50px", minHeight: "25px" }}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex_gap_10  align_items_center">
              <div style={popupStyles.categoryHeading}>
                <h4 style={popupStyles.categoryHeading}> Release Order:</h4>
                <div className="flex flex_gap_5 align_items_center">
                  <VerticalInputSelectCustomCopy
                    style={{ width: "200px", minHeight: "25px" }}
                  />
                  <span style={{ cursor: "pointer", marginTop: "0.25rem" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="15"
                      viewBox="0 0 14 15"
                      fill="none"
                    >
                      <path
                        d="M6 10.8333H7.33333V6.83325H6V10.8333ZM6.66667 5.49992C6.85556 5.49992 7.01389 5.43603 7.14167 5.30825C7.26944 5.18047 7.33333 5.02214 7.33333 4.83325C7.33333 4.64436 7.26944 4.48603 7.14167 4.35825C7.01389 4.23047 6.85556 4.16658 6.66667 4.16658C6.47778 4.16658 6.31944 4.23047 6.19167 4.35825C6.06389 4.48603 6 4.64436 6 4.83325C6 5.02214 6.06389 5.18047 6.19167 5.30825C6.31944 5.43603 6.47778 5.49992 6.66667 5.49992ZM6.66667 14.1666C5.74444 14.1666 4.87778 13.9916 4.06667 13.6416C3.25556 13.2916 2.55 12.8166 1.95 12.2166C1.35 11.6166 0.875 10.911 0.525 10.0999C0.175 9.28881 0 8.42214 0 7.49992C0 6.5777 0.175 5.71103 0.525 4.89992C0.875 4.08881 1.35 3.38325 1.95 2.78325C2.55 2.18325 3.25556 1.70825 4.06667 1.35825C4.87778 1.00825 5.74444 0.833252 6.66667 0.833252C7.58889 0.833252 8.45555 1.00825 9.26667 1.35825C10.0778 1.70825 10.7833 2.18325 11.3833 2.78325C11.9833 3.38325 12.4583 4.08881 12.8083 4.89992C13.1583 5.71103 13.3333 6.5777 13.3333 7.49992C13.3333 8.42214 13.1583 9.28881 12.8083 10.0999C12.4583 10.911 11.9833 11.6166 11.3833 12.2166C10.7833 12.8166 10.0778 13.2916 9.26667 13.6416C8.45555 13.9916 7.58889 14.1666 6.66667 14.1666ZM6.66667 12.8333C8.15555 12.8333 9.41667 12.3166 10.45 11.2833C11.4833 10.2499 12 8.98881 12 7.49992C12 6.01103 11.4833 4.74992 10.45 3.71659C9.41667 2.68325 8.15555 2.16659 6.66667 2.16659C5.17778 2.16659 3.91667 2.68325 2.88333 3.71659C1.85 4.74992 1.33333 6.01103 1.33333 7.49992C1.33333 8.98881 1.85 10.2499 2.88333 11.2833C3.91667 12.3166 5.17778 12.8333 6.66667 12.8333Z"
                        fill="#888888"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex_gap_10" style={{ marginTop: "20px" }}>
            <BgThemeButton children={"Add"} />
            <BgOutlineThemeButton children={"Cancel"} />
          </div>
        </div>
      </div>

    </>
  );
};

export default CreateContract;
