export const SvgCancel = ({ color, height, width }) => {
    return (
      <svg
        width={height ? height : "14"}
        height={width ? width : "14"}
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_11302_4819"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="14"
          height="14"
        >
          <rect width="14" height="14" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_11302_4819)">
          <path
            d="M3.73366 11.0834L2.91699 10.2667L6.18366 7.00008L2.91699 3.73341L3.73366 2.91675L7.00033 6.18341L10.267 2.91675L11.0837 3.73341L7.81699 7.00008L11.0837 10.2667L10.267 11.0834L7.00033 7.81675L3.73366 11.0834Z"
            fill={color ? color : "white"}
          />
        </g>
      </svg>
    );
  };
  
  export const SvgGoBack = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
      >
        <rect
          x="29.75"
          y="29.75"
          width="29.5"
          height="29.5"
          rx="5.75"
          transform="rotate(180 29.75 29.75)"
          stroke="#666666"
          strokeWidth="0.5"
        ></rect>
        <line x1="21" y1="15.043" x2="9" y2="15.043" stroke="#666666"></line>
        <path
          d="M13.6287 19.2578L9.00009 14.6292L13.6287 10.0007"
          stroke="#666666"
          strokeWidth="1.2"
        ></path>
      </svg>
    );
  };