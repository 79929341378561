export const ApprovedButton = ({ children }) => {
  return (
    <div
      style={{
        color: "#15AA12",
        borderRadius: "4px",
        background: "#DFF8DF",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "500",
        padding: "3px 6px",
      }}
    >
      {children}
    </div>
  );
};

export const UpdateButton = () => {
  return (
    <div
      style={{
        color: "#FFF5DC;",
        borderRadius: "4px",
        background: "#FFF5DC;",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "500",
        padding: "3px 6px",
      }}
    >
      Update
    </div>
  );
};

export const PaidButton = () => {
  return (
    <div
      style={{
        color: "#888",
        borderRadius: "4px",
        background: "#EEE",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "500",
        padding: "3px 6px",
      }}
    >
      Paid
    </div>
  );
};
export const CPButton = ({ children }) => {
  return (
    <div
      style={{
        color: "#888",
        borderRadius: "4px",
        background: "#EEE",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "500",
        padding: "3px 6px",
        display: "inline-flex",
      }}
    >
      {children}
    </div>
  );
};

export const UnpaidButton = () => {
  return (
    <div
      style={{
        color: "#888",
        borderRadius: "4px",
        background: "#EEE",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "500",
        padding: "3px 6px",
      }}
    >
      Unpaid
    </div>
  );
};

export const CancelledButton = () => {
  return (
    <div
      style={{
        color: "#E03838",
        borderRadius: "4px",
        background: "#FFEAEA",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "500",
        padding: "3px 6px",
      }}
    >
      Cancelled
    </div>
  );
};

export const PendingButton = () => {
  return (
    <div
      style={{
        color: "#E8B41A",
        borderRadius: "4px",
        background: "#FFF5DC",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "500",
        padding: "3px 6px",
      }}
    >
      Pending
    </div>
  );
};

export const PaymentStatusButton = () => {
  return (
    <div
      style={{
        color: "#3968ED",
        borderRadius: "4px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "500",
      }}
    >
      Payment Status:
    </div>
  );
};

export const BookingStatusButton = () => {
  return (
    <div
      style={{
        color: "#3968ED",
        borderRadius: "4px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "500",
      }}
    >
      Booking Status:
    </div>
  );
};

// export const BookingStatusButton = () => {
//   return (
//     <div
//       style={{
//         color: "#3968ED",
//         borderRadius: "4px",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         fontSize: "12px",
//         fontStyle: "normal",
//         fontWeight: "500",
//       }}
//     >
//       Payment Status
//     </div>
//   );
// };

export const BillGenerateButton = ({ onClick, children }) => {
  return (
    <div
      style={{
        color: "#3968ED",
        borderRadius: "4px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "500",
      }}
    >
      {children}
    </div>
  );
};

export const ViewVoucherButton = ({ children, onClick, type }) => {
  return (
    <button
      onClick={onClick}
      type={type}
      style={{
        cursor: "pointer",
        color: "#3968ED",
        borderRadius: "4px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "500",
        borderRadius: "4px",
        border: "0.5px solid #3968ED",
        padding: "10px",
        background: "transparent",
        height: "30px",
      }}
    >
      {children}
    </button>
  );
};

export const SyncButton = ({ onClick, icon }) => {
  return (
    <button icon={icon} onClick={onClick} className="BgOutlineThemeButton">
      {icon} Sync
    </button>
  );
};

export const BgThemeButton = ({
  disabled,
  children,
  onClick,
  type,
  style,
  icon,
  className,
  iconAtEnd
}) => {
  return (
    <button
      icon={icon}
      style={style}
      disabled={disabled}
      type={type}
      onClick={onClick}
      className={`BgThemeButton ${className}`}
    >
      {
        iconAtEnd === true ?
          <>
            {children}
            {icon}
          </>
          :
          <>    {icon}
            {children}

          </>
      }

    </button>
  );
};

export const BgThemeInventoryButton = ({
  disabled,
  children,
  onClick,
  type,
  style,
  icon,
  className,
}) => {
  return (
    <button
      icon={icon}
      style={style}
      disabled={disabled}
      type={type}
      onClick={onClick}
      className={`BgThemeInventoryButton ${className}`}
    >
      {icon}
      {children}
    </button>
  );
};

export const BgThemeButtonDisabled = ({
  disabled,
  children,
  onClick,
  type,
  style,
  icon,
  className,
}) => {
  return (
    <button
      // style={style}
      icon={icon}
      style={{ backgroundColor: "#95a2c8", cursor: "no-drop" }}
      disabled={true}
      type={type}
      onClick={onClick}
      className={`BgThemeButton ${className}`}
    >
      {icon}
      {children}
    </button>
  );
};

export const BgThemeButtonFullWidth = ({
  children,
  onClick,
  onChange,
  style,
  className,
  type
}) => {
  return (
    <button
      type={type}
      style={style}
      onClick={onClick}
      onChange={onchange}
      className={`BgThemeButtonFullWidth ${className}`}
    >
      {children}
    </button>
  );
};

export const BgThemeButtonFullWidthDisabled = ({
  children,
  onClick,
  onChange,
  disabled,
  style = { style },
}) => {
  return (
    <button
      style={{ backgroundColor: "#95a2c8", cursor: "no-drop" }}
      onClick={onClick}
      onChange={onchange}
      disabled={true}
      className="BgThemeButtonFullWidth"
    >
      {children}
    </button>
  );
};

export const BgWhiteButton = ({ children, onClick, onChange }) => {
  return (
    <button onClick={onClick} onChange={onchange} className="BgThemeButton">
      {children}
    </button>
  );
};

export const BgOutlineWhite = ({
  children,
  onClick,
  onChange,
  style,
  icon,
}) => {
  return (
    <button
      style={style}
      onClick={onClick}
      onChange={onchange}
      className="BgOutlineWhite"
    >
      {icon}
      {children}
    </button>
  );
};

export const WithoutBgButtonBlue = ({
  style,
  children,
  onClick,
  onChange,
  icon,
  type,
}) => {
  return (
    <button
      type={type}
      style={style}
      onClick={onClick}
      onChange={onchange}
      className="WithoutBgButtonBlue"
    >
      {icon}
      {children}
    </button>
  );
};

export const BgOutlineThemeButton = ({
  children,
  onClick,
  onChange,
  icon,
  type,
  active,
  style,
}) => {
  return (
    <button
      style={style}
      type={type}
      onClick={onClick}
      onChange={onchange}
      className={
        active === true ? "BgOutlineThemeButton active" : "BgOutlineThemeButton"
      }
    >
      {children}
      {icon}
    </button>
  );
};

export const DisabledBgOutlineThemeButton = ({
  children,
  onClick,
  onChange,
  icon,
  type,
  active,
  disabled,
  style,
}) => {
  return (
    <button
      // style={style}
      type={type}
      onClick={onClick}
      onChange={onchange}
      style={{ border: '1px solid #D6D6D6', color: '#D6D6D6', cursor: "no-drop" }}
      className={
        active === true ? "BgOutlineThemeButton active" : "BgOutlineThemeButton "
      }
      disabled={true}
    >
      {children}
      {icon}
    </button>
  );
};

export const BgOutlineBlackButton = ({
  children,
  onClick,
  onChange,
  icon,
  type,
  active,
  style,
}) => {
  return (
    <button
      style={style}
      type={type}
      onClick={onClick}
      onChange={onchange}
      className={
        active === true ? "BgOutlineBlackButton active" : "BgOutlineBlackButton"
      }
    >
      {icon}
      {children}
    </button>
  );
};

export const SettleBillButton = ({
  children,
  onClick,
  onChange,
  icon,
  type,
  active,
  style,
}) => {
  return (
    <button
      style={style}
      type={type}
      onClick={onClick}
      onChange={onchange}
      className={
        active === true ? "SettleBillButton active" : "SettleBillButton"
      }
    >
      {icon}
      {children}
    </button>
  );
};

export const PlainCancelButton = ({
  children,
  onClick,
  onChange,
  icon,
  type,
  active,
  style,
}) => {
  return (
    <button
      style={style}
      type={type}
      onClick={onClick}
      onChange={onchange}
      className={
        active === true ? "PlainCancelButton active" : "PlainCancelButton"
      }
    >
      {icon}
      {children}
    </button>
  );
};

export const CheckWithoutBgUnderlineBtn = ({
  children,
  onClick,
  style,
  type,
}) => {
  return (
    <button
      style={style}
      onClick={onClick}
      onChange={onchange}
      className="CheckWithoutBgUnderlineBtn"
      type={type}
    >
      {children}
    </button>
  );
};
