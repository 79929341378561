import React, { useCallback, useState } from "react";

const VerticalInputSelectCustomCopy = ({
  titleName,
  name,
  onChange,
  value,
  defaultValue,
  type,
  options,
  optionOnclick,
  required,
  disabled,
}) => {
  return (
    <div>
      <div style={{ marginBottom: "0.25rem" }}>
        <label htmlFor="standard-select">
          {titleName}{" "}
          {required === true ? <span style={{ color: "red" }}> *</span> : ""}
        </label>
      </div>
      <div className="select">
        <select
          disabled={disabled}
          name={name}
          value={value}
          onChange={onChange}
          type={type}
          className="select-custom"
          style={{
            width: "100%",
            minHeight: "2.25rem",
            border: "0.0625rem solid #888",
            borderRadius: "0.25rem",
            paddingLeft: "5px",
          }}
          required={required}
        >
          {options?.map((option, index) => {
            return (
              <option onClick={optionOnclick} key={index} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default VerticalInputSelectCustomCopy;
