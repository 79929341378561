import React from 'react'
import NavTabs from '../Components/NewCustomComp/NavTabs/NavTabs'
import { Outlet } from 'react-router-dom'

const AgentContractPage = () => {

    const mainData = [
        {label:"Agent Hold Rooms",to:"/agent/agent-hold-rooms"},
        {label:"Contracts",to:"/agent/agent-contracts"}
    ]

  return (
    <div className='main-container mx-20'>
      <NavTabs mainData={mainData} />

      <Outlet />
    </div>
  )
}

export default AgentContractPage
