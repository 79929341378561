// import React, { useState, useEffect } from 'react';
// import './Carousel.css';
// import style from './carousel.module.scss'

// const CarouselComponent = ({ images, animationType = 'fade' }) => {
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [animationClass, setAnimationClass] = useState('');

//   useEffect(() => {
//     setAnimationClass('carousel-image-enter');
//     const timer = setTimeout(() => setAnimationClass(''), 1000);
//     return () => clearTimeout(timer);
//   }, [currentIndex]);

//   if (!images || images.length === 0) {
//     return <div>No images available</div>;
//   }

//   const nextImage = () => {
//     setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
//   };

//   const prevImage = () => {
//     setCurrentIndex((prevIndex) =>
//       prevIndex === 0 ? images.length - 1 : prevIndex - 1
//     );
//   };

//   return (
//     <div className="carousel">
//       <button onClick={prevImage} className="carousel-button">
//         Prev
//       </button>
//       <div className={`carousel-image-container ${animationType}`}>
//         <img
//           src={images[currentIndex]}
//           alt={`Slide ${currentIndex}`}
//           className={`carousel-image ${animationClass}`}
//         />
//       </div>
//       <button onClick={nextImage} className="carousel-button">
//         Next
//       </button>
//     </div>
//   );
// };

// export default CarouselComponent;

import React, { useState, useEffect } from "react";
// import './Carousel.css';
import styles from "./carousel.module.scss";

const Carousel = ({ images, width, height }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [animationClass, setAnimationClass] = useState("");

  useEffect(() => {
    setAnimationClass("carousel-image-enter");
    const timer = setTimeout(() => setAnimationClass(""), 1000);
    return () => clearTimeout(timer);
  }, [currentIndex]);

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <>
      <div
        className={styles.carouselContainer}
        style={{ width: width, height: height }}
      >
        {images.length > 0 ? (
          <>
            <button onClick={prevImage} className={styles.carouselButton}>
              <svg
                width="20px"
                height="20px"
                viewBox="0 0 1024 1024"
                class="icon"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                transform="rotate(180)"
              >
                <path
                  d="M364.8 106.666667L298.666667 172.8 637.866667 512 298.666667 851.2l66.133333 66.133333L768 512z"
                  fill="#2196F3"
                />
              </svg>
            </button>
            <div className={styles.imageContainer}>
              <img
                src={images[currentIndex]}
                alt={`Slide ${currentIndex}`}
                className={`carousel-image ${animationClass}`}
                width={"100%"}
                height={"100%"}
              />
            </div>
            <button onClick={nextImage} className={styles.carouselButton}>
              <svg
                width="20px"
                height="20px"
                viewBox="0 0 1024 1024"
                class="icon"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M364.8 106.666667L298.666667 172.8 637.866667 512 298.666667 851.2l66.133333 66.133333L768 512z"
                  fill="#2196F3"
                />
              </svg>
            </button>
          </>
        ) : (
          <>
            <div style={{ textAlign: "center", width: "100%" }}>
              No images available
            </div>
          </>
        )}
      </div>
    
    </>
  );
};

export default Carousel;
