export const popupStyles = {
  container: {
    margin:"90 auto 0",
    width: "70%",
    borderRadius: "8px",
    background: "#fff",
    border: " 0.5px solid #ccc",
  },
  containerChild: {
    padding: "20px",
  },
  table: {
    width: "70%",
    textAlign: "left",
    borderSpacing: "0px 5px",
    marginTop: "10px",
  },
  tableBig: {
    width: "100%",
    textAlign: "left",
    borderSpacing: "0px 5px",
    marginTop: "10px",
  },
  popupHeadings: {
    color: "#333",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: "600",
    margin: "10px 0",
  },
  categoryHeading: {
    color: "#333",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "600",
    margin: "10px 0 ",
  },
  smallInput:{
    width:"60px",
    height:"20px"
  },
  agent_hold_rooms_popup_select :{
    width:"80px",
    borderRadius:"4px",
    padding:"5px"
  } 

};
