import React, { useCallback, useState, useEffect } from "react";
import { Button, Icon, Link, TextField } from "@shopify/polaris";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import {
  BgThemeButton,
  BgThemeButtonFullWidth,
  CheckWithoutBgUnderlineBtn,
} from "../UI/Buttons";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import { useDispatch, useSelector } from "react-redux";
import { saveLoginToken } from "../../reduxToolkit/Slice/loginTokenSlice";
import {
  checkIsAuthenticatedFalse,
  checkIsAuthenticatedTrue,
} from "../../reduxToolkit/Slice/isAuthenticatedSlice";
import PasswordVerticalComponent from "../CustomComponents/PasswordComponent";
import { savePermission } from "../../reduxToolkit/Slice/permissionSlice";
import { setPropertiesList } from "../../reduxToolkit/Slice/propertiesSlice";
import { saveActiveProperty } from "../../reduxToolkit/Slice/propertyslice";

const Login = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [emailEntered, setEmailEntered] = useState(false);
  const [validateEmail, setValidateEmail] = useState(false);
  const [forgotPasswordPage, setForgotPasswordPage] = useState(false);
  const [validatePassword, setValidatePassword] = useState(false);
  // const [userToken, setUserToken] = useState()

  // localStorage.clear();

  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const authenticated = useSelector(
    (state) => state.isAuthenticated.authenticated
  );

  // Check if the user is authenticated and redirect to the dashboard
  // useEffect(() => {
  //   if (authenticated === true) {
  //     navigate("/dashboard");
  //     return null;
  //   }
  // }, [authenticated, navigate]);

  const emailToPasswordHandler = () => {
    if (validateEmailFormat(email)) {
      setIsEmailValid(true);
      setEmailEntered(true);
    } else {
      setIsEmailValid(false);
    }
  };
  const userToken = useSelector((state) => state.loginToken.value);

  useEffect(() => {
    localStorage.setItem("userToken", JSON.stringify(userToken));
  }, [userToken]);

  // useEffect(() => {
  //   localStorage.setItem("authenticated", JSON.stringify(true));
  // }, [isAuthenticated]);

  const validateEmailFormat = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const submitUserData = (e) => {
    localStorage.removeItem("selectedProperty");
    e.preventDefault();
    const credentials = { email: email, password: password };
    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/agent/login`, {
      method: "POST",
      body: JSON.stringify(credentials),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setIsAuthenticated(true);
          localStorage.setItem("authenticated", JSON.stringify(true));
          dispatch(saveLoginToken(data.data.token));
          localStorage.setItem(
            "agentDetails",
            JSON.stringify(data.data.user)
          );
          dispatch(checkIsAuthenticatedTrue(true));
          
          showSuccessToast(data.message);
          setEmail("");
          setPassword("");
          fetchAgentInfo(data?.data?.user?.id);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const fetchAgentInfo = async (id) => {
    try {
      // setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agent/details/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      localStorage.setItem("properties",JSON.stringify(data.properties));
      localStorage.setItem("selectedProperty", JSON.stringify(data.properties[0]));
      dispatch(setPropertiesList(data.properties));
      localStorage.setItem("agentInfo", JSON.stringify(data.agentInfo));
      dispatch(saveActiveProperty(data.properties[0]));
      navigate("/dashboard");
      setEmail("");
      setPassword("");
      
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    } finally {
      // setLoader(false);
    }
  };



  const submitResetEmailData = (e) => {
    localStorage.removeItem("selectedProperty");
    e.preventDefault();
    const credentials = { email: email };
    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/send-password-reset-link`, {
      method: "POST",
      body: JSON.stringify(credentials),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setEmail("");
          setPassword("");
          setForgotPasswordPage(false);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  // useEffect(() => {
  //   localStorage.removeItem("selectedProperty");
  // }, []);

  const changeEmailHandler = useCallback(() => setEmailEntered(false), []);

  // console.log(authenticated);

  // if (authenticated) {
  //   navigate("/dashboard");
  //   return;
  // }

  return (
    <>
      <div className="login-screen-container">
        <div className="login-full-container">
          <form onSubmit={(e) => submitUserData(e)}>
            {!emailEntered ? (
              <>
                <div>
                  <img className="login-logo" src={logo} alt="logo" />
                </div>
                <div className="login-text-below-logo">
                  <h3>
                    {forgotPasswordPage ? "Forget Your Password?" : "Login as Agent"}
                  </h3>
                  <p>Continue to RevCatalyst</p>
                </div>
                <div>
                  {forgotPasswordPage ? (
                    <>
                      <VerticalInputFieldCustom
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        className="textfield"
                        focused={false}
                        autoComplete="off"
                        required={true}
                        titleName={"Email"}
                      />
                    </>
                  ) : (
                    <VerticalInputFieldCustom
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      className="textfield"
                      focused={false}
                      autoComplete="off"
                      required={true}
                      titleName={"Email"}
                    />
                  )}

                  {!isEmailValid ? (
                    <div
                      style={{
                        color: "red",
                        fontSize: "14px",
                        marginTop: "5px",
                      }}
                    >
                      Invalid email format
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                {forgotPasswordPage ? (
                  <div className="continue-with-email-button">
                    <BgThemeButtonFullWidth
                      style={{ width: "100%" }}
                      onClick={submitResetEmailData}
                      children={"Reset Password"}
                    />
                  </div>
                ) : (
                  <div className="continue-with-email-button">
                    <BgThemeButtonFullWidth
                      style={{ width: "100%" }}
                      onClick={emailToPasswordHandler}
                      children={"Continue With Email"}
                    />
                  </div>
                )}

                {forgotPasswordPage ? (
                  <CheckWithoutBgUnderlineBtn
                    onClick={() => setForgotPasswordPage(false)}
                    type={"button"}
                    children={"Go to Login"}
                  />
                ) : (''
                  // <CheckWithoutBgUnderlineBtn
                  //   onClick={() => setForgotPasswordPage(true)}
                  //   type={"button"}
                  //   children={"Forgot Password"}
                  // />
                )}
              </>
            ) : (
              <>
                <div>
                  <img className="login-logo" src={logo} alt="logo" />
                </div>
                <div className="login-text-below-logo">
                  <h3>
                    {forgotPasswordPage ? "Forget Your Password?" : "Login"}
                  </h3>
                  <p>
                    {forgotPasswordPage
                      ? "We’ll email instructions to example@gmail.com on how to reset it"
                      : "Continue to RevCatalyst"}
                  </p>
                </div>
                <div className="email-entered">
                  <div>{email}</div>
                  <div>
                    <Link onClick={changeEmailHandler}>Change Email</Link>
                  </div>
                </div>
                {forgotPasswordPage ? (
                  <>
                    <div className="continue-with-email-button">
                      <Button primary fullWidth={true}>
                        Reset Password
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <PasswordVerticalComponent
                        name="password"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        className="textfield"
                        focused={false}
                        required={true}
                        autoComplete="off"
                        titleName={"Password"}
                      // type={"password"}
                      />
                    </div>
                    <div className="continue-with-email-button">
                      <BgThemeButton type={"submit"} children={"Login"} />
                    </div>
                  </>
                )}
              </>
            )}
          </form>
        </div>
      </div>
    </>
  );
};
export default Login;
