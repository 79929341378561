import React, { useState, useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./selectdateRange.css";

const SelectDateRange = ({
  dataFromChild,
  defaultDate,
  disabled,
  minDate,
  maxDate,
}) => {
  const [dateRange, setDateRange] = useState([null, null]);

  console.log(dataFromChild,"asd?")
  const [startDate, endDate] = dateRange;
  // console.log(startDate, endDate);
  const datePickerRef = useRef(null);

  const CustomInput = () => (
    <div className="dateRangePicker">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <g clipPath="url(#clip0_1270_814)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.625 2H11.75V1.25C11.75 0.835786 11.4142 0.5 11 0.5C10.5858 0.5 10.25 0.835786 10.25 1.25V2H5V1.25C5 0.835786 4.66421 0.5 4.25 0.5C3.83579 0.5 3.5 0.835786 3.5 1.25V2H2.375C1.775 2 1.25 2.525 1.25 3.125V14.375C1.25 14.975 1.775 15.5 2.375 15.5H13.625C14.225 15.5 14.75 14.975 14.75 14.375V3.125C14.75 2.525 14.225 2 13.625 2ZM2.75 14H13.25V6.5H2.75V14Z"
            fill="#888888"
          />
        </g>
        <defs>
          <clipPath id="clip0_1270_814">
            <rect
              width="15"
              height="15"
              fill="white"
              transform="translate(0.5 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );

  const handleDateChange = (update) => {
    setDateRange(update);
  };

  const minSelectableDate = new Date();

  const handleContainerClick = () => {
    datePickerRef.current.setOpen(true);
  };
  const handleContainerClickDisabled = () => {
    datePickerRef.current.setOpen(false);
  };

  const [from_date, setFrom_date] = useState("");
  const [to_date, setTo_date] = useState("");

  // function formatDate(inputDate) {
  //   const currentDate = new Date(inputDate);
  //   const year = currentDate.getFullYear();
  //   const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  //   const day = String(currentDate.getDate() + 1).padStart(2, "0");
  //   const formattedDate = `${year}-${month}-${day}`;

  //   return formattedDate;
  // }

  function formatDate(inputDate) {
    let currentDate = new Date(inputDate);
    let day = currentDate.getDate() + 1;

    if (
      day >
      new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      ).getDate()
    ) {
      day = 1;
      currentDate.setDate(day);
      currentDate.setMonth(currentDate.getMonth() + 1);

      if (currentDate.getMonth() === 0) {
        currentDate.setFullYear(currentDate.getFullYear());
      }
    }

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const formattedDate = `${year}-${month}-${String(day).padStart(2, "0")}`;

    return formattedDate;
  }

  useEffect(() => {
    if (startDate && endDate) {
      const from_date = startDate.toISOString().split("T")[0];
      const to_date = endDate.toISOString().split("T")[0];

      setFrom_date(from_date);
      setTo_date(to_date);
      dataFromChild(formatDate(from_date), formatDate(to_date));
      // console.log(formatDate(from_dateD),"");
    }
  }, [startDate, endDate]);

  return (
    <div
      className={
        !disabled
          ? "selectdateRange_container"
          : "selectdateRange_container disabled"
      }
      onClick={!disabled ? handleContainerClick : handleContainerClickDisabled}
    >
      <div className="selectdateRange_text">
        {startDate || endDate ? (
          `${from_date ? formatDate(from_date) : " "} : ${
            to_date ? formatDate(to_date) : ""
          }`
        ) : (
          <>{defaultDate ? defaultDate : <>Select Date Range</>}</>
        )}
      </div>
      <div>
        {}
        <DatePicker
          className="SelectDateRangeInput"
          ref={datePickerRef}
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={handleDateChange}
          // minDate={"2023-11-21"}
          customInput={<CustomInput />}
          popperPlacement="auto"
          maxDate={maxDate}
          minDate={minDate}
        />
      </div>
    </div>
  );
};

export default SelectDateRange;
