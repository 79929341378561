import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const SidebarInnerLists = (props) => {
  const [showOtherNavMenus, setShowOtherNavMenus] = useState(false);

  const [selectedOtherBar, setSelectedOtherBar] = useState(props.firstSelected);

  const listsItems = props?.innerLists;
  const { innerLists } = props;
  const location = useLocation();

  const permission = JSON.parse(localStorage.getItem("permissions"));

  const [permissions, setPermissions] = useState(permission);

  useEffect(() => {
    setPermissions(permissions);
  }, [permissions]);

  const handleShowOtherNavMenus = () => {
    setShowOtherNavMenus(!showOtherNavMenus);
  };

  return (
    <div style={{ display: "flex" }} className="">
      <ul className="flex flex_gap_10">
        {permissions &&
          innerLists &&
          innerLists.map((item, index) => (
            <li style={{ listStyle: "none" }} key={index}>
              {permissions && permissions.includes(item.permission) && (
                <Link
                  to={item.link}
                  className={`inner_list_item ${
                    location.pathname === item.link ? "active" : ""
                  }`}
                  key={index}
                >
                  {item.name}
                </Link>
              )}
            </li>
          ))}
        {props.selectAtLast && (
          <li onClick={handleShowOtherNavMenus} style={{ listStyle: "none" }}>
            {permissions &&
              permissions.includes(props.firstSelectedPermission) && (
                <Link
                  style={{ position: "relative" }}
                  to={props.firstSelectedLink}
                  className={`inner_list_item ${
                    location.pathname === props.firstSelectedLink
                      ? "active"
                      : ""
                  }`}
                >
                  {showOtherNavMenus ? (
                    <>
                      <span>
                        <span>{selectedOtherBar}</span>
                        <span style={{ marginLeft: "4px" }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                          >
                            <path
                              d="M11 1L6 6L0.999999 1"
                              stroke="#3968ED"
                              strokeWidth="1.5"
                            />
                          </svg>
                        </span>
                      </span>
                      {showOtherNavMenus && (
                        <span className="other_menus_item_nav_items">
                          {permissions &&
                            permissions.length > 0 &&
                            props.other_select_nav_items_inventory.map(
                              (item, index) => (
                                <div key={item.id}>
                                  {permissions &&
                                    permissions.length > 0 &&
                                    permissions.includes(item.permission) && (
                                      <Link
                                        onClick={() => {
                                          setShowOtherNavMenus(false);
                                          setSelectedOtherBar(item.name);
                                        }}
                                        to={item.link}
                                        className="menus_item_list_item"
                                      >
                                        {/* {item.icon} */}
                                        {item.name}
                                      </Link>
                                    )}
                                </div>
                              )
                            )}
                        </span>
                      )}
                    </>
                  ) : (
                    <>
                      <span>
                        <span>{selectedOtherBar}</span>
                        <span style={{ marginLeft: "4px" }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                          >
                            <path
                              d="M1 7L6 2L11 7"
                              stroke="#3968ED"
                              strokeWidth="1.5"
                            />
                          </svg>
                        </span>
                      </span>
                    </>
                  )}
                </Link>
              )}
          </li>
        )}
      </ul>
    </div>
  );
};

export default SidebarInnerLists;
