import React, { useState, useEffect } from "react";
import { BgThemeButton } from "../UI/Buttons";
import Loader from "../UI/Loader/Loader";

import { customers_inner_list } from "../../assets/constant";
import {
  calculateTotalArrayObjectKey,
  getCurrentPropertyDateHandler,
} from "../../utils/utils";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// import SidebarInnerLists from "../CustomComponents/SidebarInnerLists";
import HoldRoomsAgentsPopup from "./HoldRoomsAgentsPopup";
import ReleaseRoomsManualAgentsPopup from "./ReleaseRoomsManualAgentsPopup";
import AgentHoldRoomsSliderSideBarContent from "./AgentHoldRoomsSliderSideBarContent";
import AgentHoldRoomsUpliftMenuButton from "./AgentHoldRoomsUpliftMenuButton/AgentHoldRoomsUpliftMenuButton";
import CommunicationCustomDatePicker from "../CustomComponents/CommunicationCustomDatePicker";
import {
  CustomShopifyIndexTable,
  CustomShopifyIndexTableCell,
  CustomShopifyIndexTableRow,
} from "../CustomComponents/CustomShopifyIndexTable/CustomShopifyIndexTable";
import TruncatedText from "../CustomComponents/TruncatedText/TruncatedText";
import NoDataFound from "../CustomComponents/NoDataFound";
import SliderComponentSkeleton from "../NewCustomComp/SliderComponentSkelon/SliderComponentSkeleton";

const HoldRoomPlansComp = () => {
  const agentId = JSON.parse(localStorage.getItem("agentDetails"))?.details?.agent_information

  console.log(agentId)

  const navigate = useNavigate();
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const [propertyCurrentDate, setPropertyCurrentDate] = useState(new Date());

  // const property_id = useSelector((state) => state.property.value);
  
  const property_id = JSON.parse(localStorage.getItem("selectedProperty"))
  // console.log(property_id?.id,"asd")

  // useEffect(() => {
  //   if (property_id) {
  //     getCurrentPropertyDateHandler(property_id?.id, userToken)
  //       .then((date) => {
  //         setPropertyCurrentDate(date);
  //       })
  //       .catch((error) => console.error(error));
  //   }
  // }, [property_id, userToken]);
  const [loader, setLoader] = useState(false);

  // =============side bar ========
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = (e, id) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    fetchSingleAgentDetails(id);
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleUpliftMenuClick = (option, id) => {
    if (option === "View Details") {
      toggleSidebar("", id);
    } else if (option === "Release Rooms") {
      setShowReleaseRoomPopup(true);
      const filterData = agentContracts.filter((item) => item.id === id);
      setReleaseRoomData(filterData[0]);
    } else if (option === "Delete") {
      // console.log("delete");
    } else if (option === "Create Booking") {
      navigate(`/agent-hold-rooms/create-booking/${id}`);
    } else {
      return;
    }
  };

  const handleChildData = (data) => {};

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      // 👇 Get input value
      // if (property_id) {
      // fetchInfo(property_id?.id);
      // }
    }
  };

  const [showHoldRoomsPopup, setShowHoldRoomsPopup] = useState(false);

  // slider
  const data = [
    {
      id: 1,
      company_name: "Company Name",
      from_date: "11/21/1200",
      to_date: "11/21/1200",
      hold_rooms: "123",
      agent_category: "Active",
    },
    {
      id: 2,
      company_name: "Company Name",
      from_date: "11/21/1200",
      to_date: "11/21/1200",
      hold_rooms: "123",
      agent_category: "Expired",
    },
    {
      id: 3,
      company_name: "Company Name",
      from_date: "11/21/1200",
      to_date: "11/21/1200",
      hold_rooms: "123",
      agent_category: "Active",
    },
    {
      id: 4,
      company_name: "Company Name",
      from_date: "11/21/1200",
      to_date: "11/21/1200",
      hold_rooms: "123",
      agent_category: "Expired",
    },
  ];

  const [selectedRows, setSelectedRows] = useState([]);

  const [agentContracts, setAgentContracts] = useState([]);

  const fetchHoldRoomsListing = async (queryString) => {
    setLoader(true);
    const url = queryString ? `${process.env.REACT_APP_BASE_URL}/api/v1/agent-dashboard/get/agent/hold/room/${property_id?.id}?${queryString}&agentInformationId=${agentId}`:
    `${process.env.REACT_APP_BASE_URL}/api/v1/agent-dashboard/get/agent/hold/room/${property_id?.id}?dayTypeFilter=+30/agentInformationId=${agentId}`
    try {
      const response = await fetch(
       url,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch current date from the API");
      }
      const data = await response.json();
      setAgentContracts(data.data);
      setLoader(false);
    } catch (error) {
      console.error(error);
      return "Error fetching data";
    }finally{
      setLoader(false);
    }
  };

  useEffect(() => {
    const queryString = "dayTypeFilter=90";
    if(property_id){
      fetchHoldRoomsListing(queryString);
    }
  }, [userToken]);

  const options = ["Create Booking", "View Details", "Release Rooms", "Delete"];

  //Date Range functionality

  const [customStyle, setCustomStyle] = useState(true);

  const [dayTypeFilter, setDayTypeFilter] = useState("-90");
  const [selectedDatesRange, setSelectedDatesRange] = useState({
    from_date: "",
    to_date: "",
  });


  const handleSelectedRangeChange = (selectedValue) => {
    if (selectedValue) {
      setSelectedDatesRange({
        from_date: "",
        to_date: "",
      });
      setDayTypeFilter(selectedValue);
      const queryString = `dayTypeFilter=${selectedValue}`;
      fetchHoldRoomsListing(queryString);
    }
  };
  const handleDateRangeSelected = ({ from_date, to_date }) => {
    if (from_date && to_date) {
      setDayTypeFilter("");
    }
    const queryString = `from_date=${from_date}&to_date=${to_date}`;
    fetchHoldRoomsListing(queryString);
  };

  const handleApplyButtonClick = ({ from_date, to_date }) => {
    setSelectedDatesRange({ from_date, to_date });
    if (from_date && to_date) {
      setDayTypeFilter("");
    }
    // console.log("apply");
    // fetchInfo(property_id?.id, selectedDatesRange.from_date, selectedDatesRange.to_date);
  };
  const handleOpenDatePickerData = () => {};

  const [singleAgentData, setSingleAgentData] = useState({});

  const fetchSingleAgentDetails = async (id) => {
    setLoader(true);
    try {
      const response = await fetch(
        // "https://dev.revcatalyst.in/api/v1/get/agent/hold/room/1?dayTypeFilter=+90",
        `${process.env.REACT_APP_BASE_URL}/api/v1/agent-dashboard/get/agent/hold/room/${property_id.id}?holdInventoryId=${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch current date from the API");
      }
      const data = await response.json();
      setSingleAgentData(data.data);
      setLoader(false);
    } catch (error) {
      console.error(error);
      return "Error fetching data";
    }
  };

  const totalHoldRooms = agentContracts
    .map((item) => calculateTotalArrayObjectKey(item.roomDetails, "hold_rooms"))
    .reduce((total, item) => total + item, 0);

  const [showReleaseRoomPopup, setShowReleaseRoomPopup] = useState(false);
  const [releaseRoomData, setReleaseRoomData] = useState({});

  const rowMarkup = agentContracts.map(
    (
      {
        id,
        reference_person_name,
        roomDetails,
        from_date,
        to_date,
        release_type,
      },
      index
    ) => (
      <CustomShopifyIndexTableRow
        key={index}
        id={id}
        showCheckbox={false}
        setSelectedItems={setSelectedRows}
        selectedItems={selectedRows}
      >
        <CustomShopifyIndexTableCell>
          <span
            style={{
              zIndex: "100000",
              cursor: "pointer",
              textDecoration: "underline",
              color: "#3968FF",
            }}
            onClick={(e) => toggleSidebar(e, id)}
          >
            {reference_person_name}
          </span>
        </CustomShopifyIndexTableCell>
        <CustomShopifyIndexTableCell>
          {calculateTotalArrayObjectKey(roomDetails, "hold_rooms")}
        </CustomShopifyIndexTableCell>
        <CustomShopifyIndexTableCell>
          {roomDetails.map((item, index) => (
            <>
              <div key={index}>
                {
                  <TruncatedText
                    longText={` ${item.hold_rooms} X    ${item.room_name}`}
                    width={"80px"}
                  />
                }
              </div>
            </>
          ))}
        </CustomShopifyIndexTableCell>
        <CustomShopifyIndexTableCell> {from_date}</CustomShopifyIndexTableCell>
        <CustomShopifyIndexTableCell> {to_date}</CustomShopifyIndexTableCell>
        <CustomShopifyIndexTableCell>
          {release_type?.replaceAll("_", " ")}
        </CustomShopifyIndexTableCell>

        <CustomShopifyIndexTableCell>
          <AgentHoldRoomsUpliftMenuButton
            menuOptions={options}
            handleUpliftMenuClick={handleUpliftMenuClick}
            style={{ zIndex: "2000" }}
            id={id}
          />
        </CustomShopifyIndexTableCell>
      </CustomShopifyIndexTableRow>
    )
  );
  

  return (
    <div>
      {/* <SidebarInnerLists innerLists={customers_inner_list} /> */}
      <div className="room-types-full-container">
        <div className="room-types-full-container-div">
          <div className="room-type-heading">Agent Hold Rooms</div>
          <div className="flex flex_gap_10">
            {/* <BgThemeButton
              children={"Hold Rooms"}
              onClick={() => setShowHoldRoomsPopup(true)}
            /> */}
          </div>
        </div>

        <div
          className="flex align_items_center flex_gap_10"
          style={{
            background: "#fff",
            padding: "5px 5px",
            borderRadius: "4px",
            margin: "10px 0px",
          }}
        >
          <div style={{ width: "200px" }}>
            <CommunicationCustomDatePicker
              onSelectedRangeChange={handleSelectedRangeChange}
              onApplyButtonClick={handleApplyButtonClick}
              onDateRangeSelected={handleDateRangeSelected}
              customStyle={customStyle}
              sendDataToParent={handleOpenDatePickerData}
            />
          </div>
          <div style={{ borderLeft: "1px solid #333", padding: "0 10px" }}>
            <b>{totalHoldRooms}</b> <span>Total Hold Rooms</span>
          </div>
          <div style={{ marginRight: "10px", marginLeft: "auto" }}>
            {/* <BookingSearchInputSmall
              onKeyDown={handleKeyDown}
              sendDataToParent={handleChildData}
            /> */}
          </div>
        </div>

        <div
          className="booking-choice-full-parent-container-right"
          style={{ background: "#fff" }}
        >
          {loader ? (
            <Loader />
          ) : agentContracts.length > 0 ? (
            <CustomShopifyIndexTable
              headings={[
                "Company Name",
                "Hold Rooms/Day",
                "Room Category",
                "Hold Start Date",
                "Hold End Date",
                "Release Type",
                "",
              ]}
              allItems={data}
              selectedItems={selectedRows}
              setSelectedItems={setSelectedRows}
              showCheckbox={false}
              style={{ border: "1px solid #ddd" }}
            >
              {rowMarkup}
            </CustomShopifyIndexTable>
          ) : (
            <NoDataFound />
          )}
        </div>
      </div>

      {showHoldRoomsPopup && (
        <div className="global_popup_container_background">
          <HoldRoomsAgentsPopup
            fetchHoldRoomsListing={fetchHoldRoomsListing}
            closePopup={setShowHoldRoomsPopup}
            propertyCurrentDate={propertyCurrentDate}
          />
        </div>
      )}

      {showReleaseRoomPopup && (
        <div className="global_popup_container_background">
          <ReleaseRoomsManualAgentsPopup
            setShowReleaseRoomPopup={setShowReleaseRoomPopup}
            data={releaseRoomData}
          />
        </div>
      )}
      {isSidebarOpen && (
        <SliderComponentSkeleton
          show={isSidebarOpen}
          setShow={setIsSidebarOpen}
          title={singleAgentData.reference_person_name}
        >
          <AgentHoldRoomsSliderSideBarContent
            propertyCurrentDate={propertyCurrentDate}
            singleAgentData={singleAgentData}
          />
        </SliderComponentSkeleton>
      )}
    </div>
  );
};

export default HoldRoomPlansComp;
