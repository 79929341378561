import React from "react";

const VerticalInputFieldCustom = ({
  titleName,
  name,
  onChange,
  value,
  type,
  defaultValue,
  label,
  placeholder,
  required,
  min,
  max,
  onKeyDown,
  disabled,
  inputId,
  maxLength,
  onBlur,
  style,
  error,
  format,
}) => {
  return (
    <div>
      <div className="Polaris-Labelled__LabelWrapper">
        <div className="Polaris-Label">
          <label className="Polaris-Label__Text">
            {titleName}
            {required === true ? <span style={{ color: "red" }}> *</span> : ""}
          </label>
        </div>
      </div>
      <div className="Polaris-connected">
        <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
          <div className="Polaris-TextField">
            <input
              on
              format={format}
              onDrag={() => console.log("Drag")}
              onCut={() => console.log("Cut")}
              onPaste={() => console.log("Pasted")}
              style={style}
              onBlur={onBlur}
              onWheel={(e) => e.target.blur()}
              maxLength={maxLength}
              id={inputId}
              disabled={disabled}
              onKeyDown={onKeyDown}
              required={required}
              defaultValue={defaultValue}
              autoComplete="off"
              className={
                disabled === true
                  ? "Polaris-TextField__Input Polaris-TextField__Input_disabled"
                  : "Polaris-TextField__Input"
              }
              name={name}
              onChange={onChange}
              value={value}
              type={type}
              placeholder={placeholder}
              min={min}
              max={max}
            ></input>
            <div
              className={
                error
                  ? "Polaris-TextField__Backdrop__errors"
                  : "Polaris-TextField__Backdrop"
              }
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerticalInputFieldCustom;
